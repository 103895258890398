const str = `
444444444444444444444444444444
444444444444444444444444444444
333333333333333333333333333333
333333333333333333333333333333
222222222222222222222222222222
222222222222222222222222222222
111111111111111111111111111111
111111111111111111111111111111`;

export default str;
