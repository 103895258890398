const str = `
44          44444   44       
33    33   33   33  33    33 
33    33  33     33 33    33 
22    22  22     22 22    22 
222222222 22     22 222222222
      11   11   11        11 
      11    11111         11 `;

export default str;
